const SVGSecurePortfolioSharing = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of an arrow icon on top of a circle</title>
    <rect x="5.63867" y="5.72791" width="69.7336" height="69.7336" fill="#202DAC" />
    <path
      d="M53.6995 95.9427C77.0299 95.9427 95.9429 77.0297 95.9429 53.6992C95.9429 30.3688 77.0299 11.4558 53.6995 11.4558C30.3691 11.4558 11.4561 30.3688 11.4561 53.6992C11.4561 77.0297 30.3691 95.9427 53.6995 95.9427Z"
      fill="#FD7D35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5318 45.1542V34.9957L79.0003 51.432L50.5318 67.8683V57.1172C42.4733 56.2976 34.9027 59.9894 30.5558 66.2306C30.5165 64.9764 30.5937 63.7012 30.7964 62.4171C32.353 52.5567 40.7901 45.4692 50.5318 45.1542Z"
      fill="white"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGSecurePortfolioSharing;
