const SVGInvestmentTaxReports = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of a report</title>
    <rect x="15.752" y="16.1936" width="75.8555" height="75.8555" fill="#FD7D35" />
    <path d="M67.4476 21.6504L67.4476 58.5443L99.3987 40.0973L67.4476 21.6504Z" fill="#202DAC" />
    <rect x="76" y="32" width="6" height="45" transform="rotate(90 76 32)" fill="white" />
    <rect x="76" y="44" width="6" height="45" transform="rotate(90 76 44)" fill="white" />
    <rect x="76" y="56" width="6" height="45" transform="rotate(90 76 56)" fill="white" />
    <rect x="76" y="68" width="6" height="45" transform="rotate(90 76 68)" fill="white" />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGInvestmentTaxReports;
