const SVGDividendIncomeTracking = () => (
  <svg
    width="109"
    height="108"
    viewBox="0 0 109 108"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of layered cylinders</title>
    <g clipPath="url(#clip0_3014_26863)">
      <rect x="16.6133" y="15.7517" width="75.7438" height="75.7438" fill="#FD7D35" />
      <path d="M12.1916 12.7325L12.1916 55.4647L49.1987 34.0986L12.1916 12.7325Z" fill="#202DAC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.3743 68.6985L72.3743 68.7201V68.768C72.303 73.9942 64.1879 78.2194 54.1876 78.2194C44.1431 78.2194 36.0005 73.9568 36.0005 68.6986C36.0005 63.4404 44.1431 59.1778 54.1876 59.1778C55.4676 59.1778 56.7168 59.247 57.9221 59.3787C66.175 60.2804 72.3743 64.1105 72.3743 68.6985ZM57.9221 59.3787C56.7166 59.247 55.4674 59.1777 54.1872 59.1777C44.1427 59.1777 36 63.4403 36 68.6985C36 69.5424 36.2098 70.3606 36.6034 71.1397L36.6033 71.1399C36.213 70.3674 36.0035 69.5565 36 68.7202V73.581V73.581C36.0001 78.8392 44.1427 83.1017 54.1871 83.1017C64.2316 83.1017 72.3742 78.8392 72.3743 73.581V73.581V68.768C72.3746 68.7449 72.3748 68.7217 72.3748 68.6986C72.3748 64.1105 66.1752 60.2803 57.9221 59.3787Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.3743 61.1745L72.3743 61.1961V61.2368C72.3103 66.4663 64.1923 70.6953 54.1875 70.6953C44.143 70.6953 36.0004 66.4327 36.0004 61.1745C36.0004 55.9163 44.143 51.6537 54.1875 51.6537C55.036 51.6537 55.8708 51.6841 56.6883 51.743C65.5493 52.381 72.3743 56.3605 72.3743 61.1745ZM56.6883 51.743C55.8707 51.6841 55.0357 51.6537 54.1872 51.6537C44.1427 51.6537 36 55.9163 36 61.1745C36 62.0183 36.2098 62.8366 36.6034 63.6157L36.6034 63.6157C36.213 62.8432 36.0035 62.0322 36 61.1959V66.0569V66.0569C36 71.3151 44.1427 75.5777 54.1871 75.5777C64.2316 75.5777 72.3743 71.3151 72.3743 66.0569V61.2368C72.3746 61.2161 72.3747 61.1953 72.3747 61.1745C72.3747 56.3605 65.5495 52.3809 56.6883 51.743Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.7709 56.0918C71.8036 56.0272 71.835 55.9623 71.8651 55.8972C71.835 55.9623 71.8036 56.0272 71.771 56.0919L71.7709 56.0918ZM36.6033 56.0919L36.6034 56.0917C36.2098 55.3126 36 54.4944 36 53.6505C36 48.3924 44.1427 44.1298 54.1872 44.1298C54.6434 44.1298 55.0956 44.1386 55.5434 44.1558C55.0957 44.1386 54.6436 44.1298 54.1875 44.1298C44.143 44.1298 36.0004 48.3924 36.0004 53.6506C36.0004 58.9087 44.143 63.1714 54.1875 63.1714C64.1923 63.1714 72.3103 58.9424 72.3743 53.7129V58.533V58.533C72.3743 63.7912 64.2316 68.0538 54.1871 68.0538C44.1427 68.0538 36 63.7912 36 58.533V58.533V53.6507C36 54.4946 36.2097 55.3128 36.6033 56.0919ZM72.3743 53.7129V53.6721L72.3743 53.6505C72.3743 48.6312 64.9545 44.519 55.5434 44.1558C64.9547 44.5189 72.3747 48.6311 72.3747 53.6506C72.3747 53.6714 72.3746 53.6921 72.3743 53.7129Z"
        fill="white"
      />
      <ellipse cx="54.1871" cy="50.5208" rx="18.1871" ry="9.52079" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3014_26863">
        <rect width="107.399" height="107.399" fill="white" transform="translate(0.861328)" />
      </clipPath>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGDividendIncomeTracking;
