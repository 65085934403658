import { useMemo } from 'react';

import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Cards } from 'components/Cards';
import { Notice } from 'components/Notice';
import { SignupLink } from 'components/Links';
import {
  Container,
  Section,
  SectionImage,
  HeroContainer,
  SectionVideo,
} from 'components/Container';
import { Accordion } from 'components/Accordion';
import { TrustPilot } from 'components/TrustPilot';
import { ContentBlock } from 'components/ContentBlock';
import { BlogCallout } from 'components/BlogCallout';
import { TaxDeductibleBlock } from 'components/TaxDeductibleBlock';
import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import videoThumbnail from 'images/investment-portfolio-tax/video-thumbnail.png';

import { portfolioTaxCards } from 'page-data/investment-portfolio-tax';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

import type { FC } from 'react';

export const InvestmentPortfolioTax: FC<LocalizedPageProps> = () => {
  const {
    translations: { builtForInvestors, investorsJustLikeYou },
  } = useContentfulLocale();

  const {
    translations: { waysSharesightHelpsInvestors, waysSharesightHelpsInvestorsBlogLink },
    cards,
    sections,
    accordionItems,
  } = useContentfulPage();

  const { currentLocale } = useLocalizationContext();

  const isAULocale = currentLocale.id === 'au';

  const smsfImage = useGatsbyImage({
    name: 'investment-portfolio-tax/smsf',
    alt: 'Save time on your SMSF administration',
  });

  const {
    translations: { stock },
  } = useContentfulLocale();

  const localisedTaxCards = cards?.filter(card => card.cardIdentifier.startsWith('tax-alt-'));

  const investmentPortfolioDetails = useMemo(
    () => accordionItems?.filter(accordionItem => accordionItem.question !== '-')!,
    [accordionItems]
  );

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>Tax reports for your investment portfolio</h1>
          <p>
            Tax reports built for investors like you. If you trade {stock}s, ETFs, or even cash, you
            need Sharesight.
          </p>
          <br />
          <br />
          <SignupLink asButton />
        </Section>

        <SectionVideo videoId="Y6P9Tc3M3pQ" thumbnailUrl={videoThumbnail} />
      </HeroContainer>

      <Cards cards={portfolioTaxCards} />

      <TaxDeductibleBlock />

      <BlogCallout
        title={String(waysSharesightHelpsInvestors)}
        slug={String(waysSharesightHelpsInvestorsBlogLink)}
      />

      <Container manageLayout={false}>
        <h2>Everything you need to calculate tax on your investments</h2>
        <p>
          With Sharesight, all your investment portfolio data lives in one place. You also have
          access to powerful reports that make it easy to calculate tax on your investments
          throughout the financial year.
        </p>
      </Container>

      {sections?.map(content => (
        <ContentBlock
          key={content.header}
          header={content.header}
          textContent={content.textContent}
          image={content.image}
        />
      ))}

      {isAULocale && (
        <Container>
          <Section>
            <h2>Save time on your SMSF administration</h2>
            <p>
              See your performance, diversification and asset allocation, plus track your investment
              income (including franking credits) and run your tax reports, with CGT discounts
              automatically applied.
            </p>
          </Section>
          <SectionImage image={smsfImage} boxShadow={false} />
        </Container>
      )}

      <Notice
        background="callout"
        header="Simplify your portfolio tax reporting"
        button={<SignupLink asButton />}
      />

      <SupportedBrokersBlock background="cream" />
      <Cards
        cards={localisedTaxCards}
        header={builtForInvestors}
        subHeader={investorsJustLikeYou}
      />

      <TrustPilot tagId="tax" localizeTag={false} />
      {investmentPortfolioDetails && (
        <Accordion
          title="Investment tax reporting FAQ"
          details={investmentPortfolioDetails}
          note={
            <p>
              <strong>Disclaimer:</strong> The above content is for informational purposes only and
              does not constitute a specific product recommendation, or taxation or financial advice
              and should not be relied upon as such. While we use reasonable endeavours to keep the
              information up-to-date, we make no representation that any information is accurate or
              up-to-date. If you choose to make use of the content on this page, you do so at your
              own risk. To the extent permitted by law, we do not assume any responsibility or
              liability arising from or connected with your use or reliance on the content on our
              site. Please check with your adviser or accountant to obtain the correct advice for
              your situation.
            </p>
          }
        />
      )}
    </Layout>
  );
};

export default InvestmentPortfolioTax;
